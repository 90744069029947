import { Icon } from '@chakra-ui/react';

export const Task = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path d="M14.1581 11.1111L12.3877 12.8889H16.4444V11.1111H14.1581Z" fill="currentColor" />
    <path d="M16.4444 14.6667H11.1111V16.4444H16.4444V14.6667Z" fill="currentColor" />
    <path d="M9.33333 14.6667H7.55556V16.4444H9.33333V14.6667Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8 4H19.2C19.5556 4 20 4.35556 20 4.8V19.2C20 19.5556 19.5556 20 19.2 20H4.8C4.35556 20 4 19.5556 4 19.2V4.8C4 4.35556 4.35556 4 4.8 4ZM5.77778 18.2222H18.2222V5.77778H5.77778V18.2222Z"
      fill="currentColor"
    />
    <path
      d="M6.66663 9.80445L9.81329 12.9511L14.8266 7.92001L13.5733 6.66667L9.8044 10.4356L7.91996 8.55112L6.66663 9.80445Z"
      fill="currentColor"
    />
  </Icon>
);
